export interface IPaymentMethods {
  paymentMethod: PaymentMethods;
  img: string;
  translateTxt: string;
  show: boolean;
}

export enum PaymentMethods {
  REDSYS = 'REDSYS',
  BIZUM = 'BIZUM',
  PAYPAL = 'PAYPAL',
  ONEY_3_4 = 'ONEY_3_4',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  KLARNA = 'KLARNA',
  SOFORT = 'SOFORT',
  GIROPAY = 'GIROPAY',
  STRIPE = 'STRIPE'
}
