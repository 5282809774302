import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { BasketSelectors } from '@fgp/basket/data-access';
import { ICarritoCompra } from '@fgp/carrito/interfaces';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Store } from '@ngrx/store';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuard {
  constructor(
    private router: Router,
    private sessionService: AdminSessionService,
    public activatedRoute: ActivatedRoute,
    private store: Store,
    private localizeService: LocalizeRouterService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let compraRapida = false;
    if (next.queryParams) {
      compraRapida = next.queryParams.compraRapida ? next.queryParams.compraRapida === 'true' : false;
    }
    return this.comprobarCesta(compraRapida);
  }

  cestaVacia(carrito: ICarritoCompra): boolean {
    return !carrito.productoOnlineCestaLineas || carrito.productoOnlineCestaLineas.length === 0;
  }

  comprobarCesta(compraRapida: boolean) {
    if (!this.sessionService.getIdCarrito()) {
      this.router.navigate([this.localizeService.translateRoute('/')]);
      return false;
    }
    return this.store.select(BasketSelectors.selectBasket).pipe(
      filter(basket => basket.loaded),
      map(basket => {
        if (basket) {
          if (this.cestaVacia(basket)) {
            this.router.navigate([this.localizeService.translateRoute('/')]);
            return false;
          }
          if (this.sessionService.isAuthenticate()) {
            if (basket.direccionEnvio != null) {
              return true;
            } else {
              this.router.navigate(['/caja/direccion'], {
                queryParams: {
                  compraRapida
                }
              });
              return false;
            }
          } else {
            if (compraRapida) {
              this.router.navigate(['/caja/direccion'], {
                queryParams: {
                  compraRapida: compraRapida
                }
              }); // vuelvo al paso 2
              return false;
            } else {
              this.router.navigate(['/caja/login'], {
                queryParams: {
                  compraRapida: compraRapida
                }
              }); // vuelvo al paso 2
              return false;
            }
          }
        } else {
          return false;
        }
      })
    );
  }
}
