import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CkeckMethodEffects } from './chek-payment.effects';
import { checkMethodPaymentFeature } from './chek-payment.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(checkMethodPaymentFeature), EffectsModule.forFeature([CkeckMethodEffects])]
})
export class CkeckMethodPaymentModule {}
