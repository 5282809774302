import { PaymentMethods } from '@fgp/checkout/interface';
import { createFeature, createReducer, on } from '@ngrx/store';
import { CheckMethodPaymentActions } from './chek-payment.actions';
import { CkeckMethodPaymentState } from './chek-payment.state';

export const initialState: CkeckMethodPaymentState = {
  privacity: false,
  method: PaymentMethods.REDSYS
};

export const checkMethodPaymentFeature = createFeature({
  name: 'checkMethodPayment',
  reducer: createReducer(
    initialState,
    on(CheckMethodPaymentActions.clickedPrivacity, (state, { privacity }) => ({ ...state, privacity })),
    on(CheckMethodPaymentActions.selectedPayment, (state, { method }) => ({ ...state, method }))
  )
});
