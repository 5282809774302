import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import {
  IAppConfig,
  IServicePaymentSimulation,
  IServicioComprarAhora,
  IServicioComprarAhoraPaypal,
  IServicioComprarAhoraStripe,
  IServicioEstadoCompra
} from '@fgp/shared/interfaces';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ComprarAhoraService {
  private urlApiPedidoPublica = this.appConfig.apiPublicUrl + 'online/pedido';
  private urlApiPaymentGateway = this.appConfig.apiPublicUrl + 'online/payment-gateway';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) {}

  preparaDatosPagoTarjetaRedsys(idCesta: string): Observable<IServicioComprarAhora> {
    const url = `${this.urlApiPedidoPublica}/${idCesta}/pago/prepara/redsys/tarjeta`;

    return this.http.post<IServicioComprarAhora>(url, '', httpOptions);
  }

  preparaDatosPagoTarjetaOney(idCesta: string, businessTransactionCode: string): Observable<IServicioComprarAhora> {
    const url = `${this.urlApiPedidoPublica}/${idCesta}/pago/prepara/oney`;

    return this.http.post<IServicioComprarAhora>(url, { businessTransaction: businessTransactionCode }, httpOptions);
  }

  preparaDatosPagoStripe(idCesta: string): Observable<IServicioComprarAhoraStripe> {
    const url = `${this.urlApiPedidoPublica}/${idCesta}/pago/prepara/stripe`;

    return this.http.post<IServicioComprarAhoraStripe>(url, httpOptions);
  }

  preparaDatosPagoBizumRedsys(idCesta: string): Observable<IServicioComprarAhora> {
    const url = `${this.urlApiPedidoPublica}/${idCesta}/pago/prepara/redsys/bizum`;

    return this.http.post<IServicioComprarAhora>(url, '', httpOptions);
  }

  preparaDatosPagoPaypal(idCesta: string): Observable<IServicioComprarAhora> {
    const url = `${this.urlApiPedidoPublica}/${idCesta}/pago/prepara/paypal`;

    return this.http.post<IServicioComprarAhora>(url, '', httpOptions);
  }

  confirmaDatosPagoPaypal(idCesta: string, orderId: string): Observable<IServicioComprarAhoraPaypal> {
    const url = `${this.urlApiPedidoPublica}/${idCesta}/pago/confirma/paypal`;

    return this.http.post<IServicioComprarAhoraPaypal>(url, orderId, httpOptions);
  }

  confirmarEstadoPago(idPedido: string, referenciaPedido: string): Observable<IServicioEstadoCompra> {
    const url = `${this.urlApiPedidoPublica}/${idPedido}/pago/exito`;

    return this.http.post<IServicioEstadoCompra>(url, referenciaPedido, httpOptions);
  }

  getOneyPaymentSimulationProduct(paymentAmount: number): Observable<IServicePaymentSimulation> {
    const p = new HttpParams().set('paymentAmount', String(paymentAmount));
    return this.http.get<IServicePaymentSimulation>(this.urlApiPaymentGateway.concat('/simulation/product'), { params: p });
  }

  getOneyPaymentSimulationShoppingCard(idCesta: string): Observable<IServicePaymentSimulation> {
    const p = new HttpParams().set('idShoppingCard', idCesta);
    return this.http.get<IServicePaymentSimulation>(this.urlApiPaymentGateway.concat('/simulation/shopping-card'), { params: p });
  }
}
