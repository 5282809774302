import { PaymentMethods } from '@fgp/checkout/interface';
import { createActionGroup, props } from '@ngrx/store';

const checkMethodPayment = createActionGroup({
  source: 'methodPayment',
  events: {
    'Clicked Privacity': props<{ privacity: boolean | null }>(),
    'Selected Payment': props<{ method: PaymentMethods | null }>()
  }
});

export const CheckMethodPaymentActions = {
  ...checkMethodPayment
};
